input {
    -webkit-user-select: auto !important;
    -khtml-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    -o-user-select: auto !important;
    user-select: auto !important;  
    width: 90%;
    height: 90%;
}

.Page { 
    background-color: #fafafa;
}



.NavBar{
    background-color: darkslateblue !important;
}

.NavBar a{
    color:#fafafa !important;
}

button.primaryButton {
    border-radius: 12px;
    width: 120px;
    margin-right: 10px;
    color:#fafafa;
}

button.secondaryButton {
    background-color: transparent;
    border-radius: 12px;
    width: 120px;
}